/*
 *   File : occupation.js
 *   Author URI : www.evoqins.com
 *   Description : Select box will allow the customers to pick the desired option from a pre created list.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* import package */
import React, { Fragment, useEffect, useState } from "react";
import Select, { components } from "react-select";

/* import components */
import Icon from "../Icon/icon";

import Colors from "../../Styles/color.module.scss";

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon icon="header-drop-down"
                width="16px"
                height="16px"
                color="#65308C" />
        </components.DropdownIndicator>
    );
};


const CustomDropdown = (props) => {

    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const customStyles = {
        container: (defaultStyles, state) => ({
            ...defaultStyles,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0",
            color: Colors.medium_gray,
            fontSize: "14px",
            lineHeight: "20px",
            paddingLeft: "2px",
            fontFamily: "Montserrat-Regular"
        }),
        input: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0",
            padding: "0"
        }),
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderRadius: "12px",
            border: state.isFocused ? `1px solid ${Colors.rebecca_purple}` : `1px solid ${Colors.silver_sand}`,
            background: "transparent",
            padding: window.innerWidth > 576 ? "12px 16px" : "10px 14px",
            boxShadow: "none",
            "&:hover": {
                border: state.isFocused ? `1px solid ${Colors.rebecca_purple}` : `1px solid ${Colors.silver_sand}`,
                cursor: 'pointer',
            },
            "&[aria-disabled = true]": {
                background: Colors.light_lavender
            }
        }),
        valueContainer: (defaultStyles) => ({
            ...defaultStyles,
            padding: "0px",
        }),


        menuList: (defaultStyles, state) => ({
            ...defaultStyles,
            position: 'relative', // Ensure relative positioning for the scrollbar to appear correctly
            maxHeight: "180px",
            '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
                display: "block",
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: `${Colors.light_lavender} !important`,
            },
        }),

        dropdownIndicator: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "0",
            marginLeft: "4px",
            transition: "transform 0.2s",
            transform: state.selectProps.menuIsOpen === true ? "rotate(-180deg)" : ""
        }),
        menu: (defaultStyles, state) => ({
            ...defaultStyles,
            overflow: "hidden",
            padding: "0px",
            borderRadius: "16px",
            zIndex: '2',
            border: `1px solid ${Colors.athens_gray}`,
            boxShadow: "5px 3px 10px 0px #0000001A"
        }),
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "12px 16px",
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            fontStyle: " normal",
            fontWeight: "500",
            lineHeight: "24px",
            letterSpacing: "0.07em",
            backgroundColor: Colors.white,

            // borderRadius: "12px",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: Colors.light_lavender,
            },
            "&:not(:last-child)": {
                borderBottom: `solid 1px ${Colors.bright_gray}`,
            },

        }),

        singleValue: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "0",
            fontFamily: "Montserrat-Medium",
            color: Colors.charleston_green
        }),
    };

    const _onSelectChange = (value) => {
        props.onSelectChange(value)
    }

    // Custom Option component with the icon
    const CustomOption = ({ children, isSelected, ...props }) => (
        <components.Option {...props}>
            <div className="d-flex align-items-center gap-10px">
                {
                    isSelected ?
                        <Icon icon="radio-checked"
                            size={24} />
                        :
                        <Icon icon="radio-unchecked"
                            size={24} />
                }
                <span className={isSelected ? "color-rebecca-purple" : "color-eerie-black"}>{children}</span>
            </div>
        </components.Option>
    );

    const CustomSingleValue = ({ children, ...single }) => (
        <components.SingleValue {...single}>
            <div className="d-flex align-items-center gap-8px">
                {single.selectProps.value.value}
            </div>
        </components.SingleValue >

    );


    return (
        <Fragment>
            <div className={`${props.className}  position-relative`}>
                <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text margin-6px-bottom bg-transparent`}
                    htmlFor={props.label}>
                    {props.label}
                    <span className='color-deep-carmine-pink'>
                        {props.postfix}
                    </span>
                </p>
                <Select value={value}
                    styles={customStyles}
                    isSearchable={props.isSearchable}
                    options={props.options}
                    placeholder={props.placeholder}
                    isDisabled={props.isDisabled}
                    menuPlacement={props.menuPlacement}
                    noOptionsMessage={() => <span className="e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 py-3">{props.message}</span>}
                    // menuIsOpen={true}
                    components={props.isSingleValue === true ? {
                        DropdownIndicator,
                        IndicatorSeparator: null,
                        Option: CustomOption,
                        SingleValue: CustomSingleValue
                    } :
                        {
                            DropdownIndicator,
                            IndicatorSeparator: null,
                            Option: CustomOption,
                        }
                    }
                    onChange={(value) => {
                        _onSelectChange(value)
                    }}
                />
                {/* Error text */}
                {
                    props.error &&
                    <div className='position-absolute d-flex align-items-center margin-6px-top w-max-content'>
                        <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                            {props.error}
                        </p>
                    </div>
                }
            </div>

        </Fragment>
    );
}

CustomDropdown.defaultProps = {
    isSearchable: false
}

export default CustomDropdown;
/*
*    
*   File : home.js
*   Author : https://evoqins.com
*   Description : Home page
*   Integrations : NA
*   Version : 1.0.0
*/

// import packages
import Slider from "react-slick";
import { Fragment, useEffect, useRef, useState } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCreative, Pagination } from 'swiper/modules';

// import components
import { Header } from "../../Components/Header";
import { LinkButton, PrimaryButton } from "../../Components/Buttons";
import { FundCategoryCard, ResourceCard } from "../../Components/Card";
import { Loader, SectionTitle } from "../../Components/Other";
import { GoalCard, InvestmentCard, ModelPortfolioCard } from "../../Components/Cards";
import { Icon } from "../../Components/Icon";
import { EsignCreateModal, ImportExternalFundsModal, KYCConfirmationModal, VideoPreviewModal } from "../../Components/Modal";

// import services
import APIService from "../../Services/api-service";
import { _getPathFromShortName, useWindowSize } from "../../Helper/helper";

// Styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Color from '../../Styles/color.module.scss'
import { _getProfile } from "../../Helper/api";


const INVESTMENT_TYPES = [
    {
        label: "Overview",
        id: 1
    },
    {
        label: "Categories",
        id: 2
    },
    {
        label: "Funds",
        id: 3
    },
];



const Home = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { width } = useWindowSize();

    const webRef = useRef(false);
    const mobileRef = useRef(false);
    const basketRef = useRef(false);

    const [loader, setLoader] = useState(true);
    // const [portfolioLoader, setPortfolioLoader] = useState(false);
    const [homePageData, setHomePageData] = useState({});
    const [banner, setBanner] = useState([]);

    const [esignModal, setEsignModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [investmentType, setInvestmentType] = useState(INVESTMENT_TYPES[0].id);
    const [showPreview, setShowPreview] = useState(false);
    const [importFunds, setImportFunds] = useState(false);


    useEffect(() => {
        function handleResize() {
            const isWeb = window.innerWidth >= 576;

            if (isWeb && webRef.current === false) {
                setLoader(true);
                _getHomeDetails("web");
                webRef.current = true;
                mobileRef.current = false;
            } else if (!isWeb && mobileRef.current === false) {
                setLoader(true);
                _getHomeDetails("mobile");
                mobileRef.current = true;
                webRef.current = false;
            }
        }

        handleResize()

        window.scrollTo(0, 0)
        // Add the resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        if (location.state !== null && location.state.basket) {
            if (loader === false) {
                setTimeout(() => {
                    basketRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }, 50);
            }
        }
        // eslint-disable-next-line
    }, [loader]);


    useEffect(() => {
        if (showPreview === true) {
            const modal = new Modal(document.getElementById("video-preview"));
            modal.show();
        }
    }, [showPreview]);


    useEffect(() => {
        if (importFunds === true) {
            const modal = new Modal(document.getElementById("import-funds"))
            modal.show();
        }
    }, [importFunds]);

    useEffect(() => {
        if (esignModal === true) {
            const modal = new Modal(document.getElementById("create-esign"));
            modal.show();
        }
    }, [esignModal]);

    useEffect(() => {
        if (showSuccess === true) {
            const modal = new Modal(document.getElementById("confirm"));
            modal.show();
        }
    }, [showSuccess]);

    // handle esign modal
    function _initiateEsign(status) {
        setEsignModal(status);
    }

    function _handleInvestmentType(tab_index) {
        setInvestmentType(tab_index);
    }

    // handle confirm investment
    function _confirmInvestment(status) {
        setShowSuccess(status);
    }

    const _handleFinancialFreedom = () => {
        navigate("/financial-freedom", {
            state: {
                data: homePageData
            }
        })
    }

    function _showPreview(status) {
        setShowPreview(status);
    }

    function _handleImport(status) {
        setImportFunds(status);
    }

    const _handleNudgeNavigate = (item) => {
        if (item.is_static === true) {
            if (item.navigation_type === 6) {
                // Open URLs outside the app
                if (item.redirection_url !== null) {
                    // enable navigation only if we have URL
                    window.open(item.redirection_url, "_blank");
                } else {
                    _handleReCheckKyc();
                }
            } else if (item.short_name === 'new-kyc') {
                // Navigation to Check PAN
                navigate("/kyc");
            } else if (item.short_name === "kyc-validated" || item.short_name === "kyc-verified") {
                navigate("/explore")
            } else if (item.short_name === 'kyc-in-progress' || item.short_name === "kyc-rejected") {
                navigate("/kyc");
            } else if (item.short_name === 'esign') {
                _initiateEsign(true);
            } else if (item.short_name === "risk") {
                // Risk score page
                navigate("/risk-profile");
            } else if (item.short_name === "milestone") {
                // milestone listing
                navigate("/milestone");
            }
        } else {
            if (item.navigation_type === 3) {
                navigate("/resource/detail", {
                    state: {
                        id: item.nav_id,
                        tab_index: null,
                        page: 1
                    }
                });
                // Resource detail page
            } else if (item.navigation_type === 4) {
                // Navigation to specific goals
                if (item.short_name) {
                    navigate(`/milestone/${_getPathFromShortName(item.short_name)}`, {
                        state: {
                            goal_id: item.nav_id,
                            step: 1
                        }
                    });
                }
            } else if (item.navigation_type === 5) {
                // Open URLs in APP
                if (item.redirection_url.length !== 0) {
                    // enable navigation only if we have URL
                    navigate(item.redirection_url);
                }
            } else if (item.navigation_type === 6) {
                // Open URLs outside the app
                if (item.redirection_url) {
                    // enable navigation only if we have URL
                    window.open(item.redirection_url, "_blank");
                } else {
                    _handleReCheckKyc();
                }
            }
        }
    }

    const _handleBannerNavigate = (item) => {
        if (item.content_type === 1) {
            // open the URL in a new tab
            if (item.redirection_url.length !== 0) {
                window.open(item.redirect_url, '_blank');
            } else {
                _showPreview(true);
            }
        } else if (item.content_type === 2) {
            // navigate("/portfolio", {
            //     state: {
            //         fund_type: 2,
            //     }
            // })
            _handleImport(true)
            // external fund import. Will implement it later
        } else if (item.content_type === 3) {
            if (item.navigation_type === 3) {
                // Resource detail
                navigate("/resource/detail", {
                    state: {
                        id: item.nav_id,
                        tab_index: null,
                        page: 1
                    }
                });
            } else if (item.navigation_type === 4) {
                // Goal detail
                navigate(`/milestone/${_getPathFromShortName(item.short_name)}`, {
                    state: {
                        goal_id: item.nav_id,
                        step: 1
                    }
                });

            } else if (item.navigation_type === 5) {
                if (item.redirection_url.length !== 0) {
                    // enable navigation only if we have URL
                    window.open(item.redirect_url, '_blank');
                }
            }
        }
    }


    // API - get home data
    const _getHomeDetails = (type) => {
        const url = "/home/get"

        const request = {
            platform: type
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setHomePageData(response.data);
                const nudge_data = response.data.nudge_card.map((item, key) => ({
                    ...item,
                    id: key,
                }));
                setBanner(nudge_data);
            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
            setLoader(false);
        })
    }


    const _handleReCheckKyc = () => {
        const url = "/kyc/recheck"
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                _getProfile().then((response) => {
                    navigate("/manage-kyc");
                })
            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }
    return (
        <>
            {
                loader === true ?
                    <Loader height="h-80vh" />
                    :
                    <Fragment>
                        <div className="e-home-page border-radius-tl-40px ">
                            <Header type={1} />
                            <div className="row ">
                                <div className="col-lg-11 pe-lg-0">
                                    <div className="mt-3 px-4">

                                        {/* Header */}
                                        <section className="mb-4 pb-md-3 pb-0">
                                            <div className="row row-gap-16px">
                                                {
                                                    banner.length > 0 &&
                                                    <div className="col-lg-8 position-relative">
                                                        <Fragment>
                                                            <Swiper
                                                                autoplay={{
                                                                    delay: 3000,
                                                                    disableOnInteraction: true
                                                                }}
                                                                loop={true}
                                                                effect={'creative'}
                                                                creativeEffect={{
                                                                    prev: {
                                                                        shadow: true,
                                                                        translate: [0, '-100%', -400],
                                                                    },
                                                                    next: {
                                                                        translate: [0, '100%', 0],
                                                                        shadow: true,
                                                                        shadowOffset: 'bottom',  // Custom property to indicate shadow on bottom
                                                                    },
                                                                }}
                                                                pagination={{
                                                                    clickable: true,
                                                                }}
                                                                modules={[Autoplay, EffectCreative, Pagination]}
                                                                className={`mySwiper position-relative z-index-3 ${homePageData.signup_progress === "INVESTED" ? "h-326px" : "h-260px"}`}
                                                            >
                                                                {
                                                                    banner.map((item, index) => {
                                                                        return (
                                                                            <SwiperSlide key={index} className="position-relative ">
                                                                                <img
                                                                                    src={item.image}
                                                                                    className="w-100 h-100 position-relative border-radius-24px cursor-pointer "
                                                                                    onClick={() => _handleNudgeNavigate(item)}
                                                                                    alt={`Slide ${index + 1}`}
                                                                                />

                                                                            </SwiperSlide>
                                                                        )
                                                                    })
                                                                }


                                                            </Swiper>
                                                            {/* <div className="first-shadow h-290px w-90 top-10px z-index-2 object-fit-cover"></div>
                                                            <div className="first-shadow h-300px w-85 top-20px z-index-1 object-fit-cover"></div> */}
                                                        </Fragment>
                                                    </div>
                                                }

                                                {/* Banner slider */}
                                                <div className={banner.length > 0 ? `col-lg-4 ${homePageData.signup_progress === "INVESTED" ? "d-block" : "d-lg-block d-none"}` : "col-md-8"}>
                                                    <InvestmentCard tabs={INVESTMENT_TYPES}
                                                        selected={investmentType}
                                                        isInvested={homePageData.signup_progress === "INVESTED" ? true : false}
                                                        onSelectTab={_handleInvestmentType}
                                                        data={homePageData.investment_data} />
                                                </div>

                                                {/* enbale navigation to Portfolio */}
                                                <div className={banner.length > 0 ? "d-none" : "col-md-4 d-md-block d-none"}>
                                                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                        <button className="e-customize-btn gap-8px e-font-14 e-line-height-24 e-montserrat-semi-bold padding-12px-tb px-3 d-flex gap-8px align-items-center color-rebecca-purple"
                                                            onClick={() => navigate("/portfolio")}>
                                                            <img src={require("../../Assets/Images/Home/wallet.svg").default}
                                                                alt="Wallet" />
                                                            View Portfolio
                                                            <Icon icon="Arrow-Right" size={24}
                                                                color={Color.rebecca_purple}
                                                                className="e-arrow-right" />
                                                        </button>

                                                    </div>
                                                </div>

                                            </div>
                                        </section>

                                    </div>
                                </div>
                            </div>
                            <div className="e-bg-gradient padding-32px-top padding-48px-bottom px-4 border-radius-tl-40px">
                                <div className="row ">
                                    <div className="col-lg-11 pe-lg-0">

                                        {/* Financial Pyramid */}
                                        <section className="bg-white border-radius-32px  padding-40px-lr py-lg-0 pt-4 pb-0 position-relative">
                                            <div className="row">
                                                <div className="col-md-8 d-flex flex-column">
                                                    <p className="e-montserrat-semi-bold e-font-24 e-line-height-30 color-eerie-black mb-md-3 mb-2 pt-md-5 pt-0">Financial Pyramid</p>
                                                    <p className="e-montserrat-regular e-font-18 e-line-height-24 color-gunmetal mb-4">
                                                        A unique approach from stability to growth.
                                                    </p>
                                                    <PrimaryButton className="padding-10px-tb px-3 border-radius-40px position-relative z-index-1 d-md-block d-none w-max-content"
                                                        label={homePageData.is_pyramid_updated ? "Current Financial Pyramid Status" : "Unlock Financial Freedom"}
                                                        onPress={_handleFinancialFreedom} />
                                                    <p className="e-montserrat-regular e-font-18 e-line-height-24 color-gunmetal mt-auto mb-4 d-md-block d-none">
                                                        Discover the financial pyramid secrets.
                                                    </p>
                                                </div>
                                                <div className="col-md-4 mb-4">
                                                    <img src={require("../../Assets/Images/Home/financial-pyramid.png")}
                                                        alt="Financial Pyramid"
                                                        draggable={false}
                                                        className="w-100 h-280px object-fit-contain d-md-block d-none" />
                                                    <img src={require("../../Assets/Images/Home/financial-pyramid.png")}
                                                        alt="Financial Pyramid"
                                                        draggable={false}
                                                        className="w-100 h-220px object-fit-contain d-md-none d-block" />
                                                    <p className="e-montserrat-regular e-font-18 e-line-height-24 color-gunmetal mb-4 d-md-none d-block text-center">
                                                        Discover the financial pyramid secrets.
                                                    </p>
                                                    <div className="d-flex justify-content-center d-md-none">
                                                        <PrimaryButton className="padding-10px-tb px-3 border-radius-40px"
                                                            label={homePageData.is_pyramid_updated ? "Current Financial Pyramid Status" : "Unlock Financial Freedom"}
                                                            onPress={_handleFinancialFreedom} />
                                                    </div>
                                                </div>
                                            </div>
                                            <img src={require("../../Assets/Images/Home/financial-pyramid-pattern.png")}
                                                alt="Pattern"
                                                draggable={false}
                                                className="position-absolute top-0 start-0 h-100 d-md-block d-none" />
                                        </section>

                                        {/* Building bridges */}
                                        {
                                            // eslint-disable-next-line
                                            homePageData.banner_data.map((item) => {
                                                if (item.content_type === 1) {
                                                    return (
                                                        <section className={`cursor-pointer margin-32px-top`}
                                                            onClick={() => _handleBannerNavigate(item)}>
                                                            <img src={item.image_url} alt=""
                                                                className="img-fluid" />
                                                        </section>
                                                    )
                                                }
                                            })
                                        }

                                        {/* model portfolio */}
                                        {
                                            // portfolioLoader === true ?
                                            //     <Loader height="h-600vh box-shadow-33 border-radius-32px border-lavender-blue py-4 bg-white margin-32px-top" />
                                            //     :
                                            homePageData.is_risk_done === true &&
                                            <section className="margin-32px-top" ref={basketRef}>
                                                <ModelPortfolioCard data={homePageData.portfolio_data}
                                                    investmentType={homePageData.investment_style_text}
                                                    basketTypeId={homePageData.basket_type_id}
                                                    basketType={homePageData.basket_type}
                                                    isInvested={homePageData.is_basket_invested}
                                                    invested={() => _getHomeDetails("web")}
                                                    updateFunds={() => _getHomeDetails("web")} />
                                            </section>
                                        }

                                        {/* Mutual fund store */}
                                        <section className="margin-40px-top d-md-block d-none">
                                            <SectionTitle label="Mutual Fund store"
                                                className="mb-md-4 mb-0" />
                                            <div className="row">
                                                {
                                                    homePageData.mf_categories.map((item, key) =>
                                                        <div className="col-xl-2 col-lg-3 col-md-3 col-6 position-relative"
                                                            key={key}>
                                                            <FundCategoryCard data={item} />
                                                        </div>)
                                                }
                                            </div>
                                        </section>

                                        <section className="margin-40px-top d-md-none d-block">
                                            <div className="bg-white box-shadow-black border-radius-24px p-3">
                                                <h3 className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-0">Mutual Fund store</h3>
                                                {/* <Slider slidesToShow={4}
                                                    slidesToScroll={1}
                                                    autoplaySpeed={3000}
                                                    autoplay={true}
                                                    dots={false}
                                                    arrows={false}
                                                    {...{
                                                        responsive: [
                                                            {
                                                                breakpoint: 576,
                                                                settings: {
                                                                    slidesToShow: 3,
                                                                    slidesToScroll: 1
                                                                }
                                                            }
                                                        ]
                                                    }}
                                                    dotsClass={"slick-dots e-words-slider"}> */}
                                                <div className="row gx-0">
                                                    {
                                                        homePageData.mf_categories.map((item, key) =>
                                                            <div className="col-sm-4 col-4 position-relative"
                                                                key={key}>
                                                                <FundCategoryCard data={item} show_name={width < 576 ? true : false} />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                {/* </Slider> */}
                                            </div>

                                        </section>

                                        {/* Goals */}
                                        {
                                            homePageData.goal.length !== 0 &&
                                            <section className="margin-40px-top d-sm-block d-none">
                                                <div className=" d-flex flex-wrap justify-content-between mb-4">
                                                    <SectionTitle label="Milestone Oriented Investing" />
                                                    <LinkButton label="View More"
                                                        className="e-font-16 e-line-height-24 e-view-all" onPress={() => navigate("/milestone")} />
                                                </div>
                                                <div className="row justify-content-xl-start justify-content-center">
                                                    {
                                                        homePageData.goal.map((item, key) =>
                                                            <div key={key} className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-4 pb-4">
                                                                <GoalCard data={item} />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </section>
                                        }

                                        {
                                            homePageData.goal.length !== 0 &&
                                            <section className="margin-40px-top d-sm-none d-block">
                                                <div className=" d-flex flex-wrap justify-content-between mb-0">
                                                    <SectionTitle label="Milestone Oriented Investing" />
                                                    <LinkButton label="View More"
                                                        className="e-font-16 e-line-height-24 e-view-all" onPress={() => navigate("/milestone")} />
                                                </div>

                                                <Slider slidesToShow={3}
                                                    slidesToScroll={1}
                                                    autoplaySpeed={4000}
                                                    autoplay={true}
                                                    initialSlide={1}
                                                    dots={false}
                                                    arrows={false}
                                                    {...{
                                                        responsive: [
                                                            {
                                                                breakpoint: 420,
                                                                settings: {
                                                                    slidesToShow: 2,
                                                                    slidesToScroll: 1
                                                                }
                                                            }
                                                        ]
                                                    }}
                                                    dotsClass={"slick-dots e-words-slider"}>
                                                    {
                                                        homePageData.goal.map((item, key) =>
                                                            <div key={key} className="px-2 py-3">
                                                                <GoalCard data={item} />
                                                            </div>
                                                        )
                                                    }
                                                </Slider>
                                            </section>
                                        }

                                        {/* Monitor and review all mf */}
                                        {
                                            // eslint-disable-next-line
                                            homePageData.banner_data.map((item) => {
                                                if (item.content_type === 2) {
                                                    return (
                                                        <section className="mt-3">
                                                            <img src={item.image_url} alt=""
                                                                className="img-fluid cursor-pointer"
                                                                onClick={() => _handleBannerNavigate(item)} />

                                                        </section>
                                                    )
                                                }
                                            })}
                                        {/* Words of wisdom */}
                                        <section className="margin-40px-top d-flex justify-content-between mb-md-4 mb-2">
                                            <SectionTitle label="Words Of Wisdom" />
                                            {/* <LinkButton label="View All" className="e-font-18 e-line-height-30"
                                                onPress={() => navigate("/resource")} /> */}
                                        </section>
                                        {/* <Slider slidesToShow={1}
                                            slidesToScroll={1}
                                            autoplaySpeed={3000}
                                            fade={true}
                                            autoplay={true}
                                            dots={true}
                                            arrows={false}
                                            {...settings}
                                            dotsClass={"slick-dots e-words-slider"}> */}

                                        <img src={require("../../Assets/Images/Home/words-of-wisdom.png")}
                                            alt="Home"
                                            draggable={false}
                                            className="w-100 object-fit-cover h-auto d-sm-block d-none" />
                                        <img src={require("../../Assets/Images/Home/wisdom_banner.png")}
                                            alt="Home"
                                            draggable={false}
                                            className="w-100 object-fit-cover h-auto d-sm-none d-block" />


                                        {/* </Slider> */}


                                        {/* Resources  */}
                                        {(homePageData.resource !== null && homePageData.resource.length > 0) && <Fragment>
                                            <div className="d-flex justify-content-between align-items-center margin-32px-top mb-sm-4 mb-0">
                                                <SectionTitle label="In Your Interest" />
                                                <LinkButton label="View All" className="e-font-16 e-line-height-24 e-view-all"
                                                    onPress={() => navigate("/resource", {
                                                        state: {
                                                            tab_index: null,
                                                            page: 1
                                                        }
                                                    })} />
                                            </div>
                                            <section className="row d-md-flex d-none">
                                                {homePageData.resource.map((item, index) => {
                                                    return (
                                                        <div key={index} className="col-md-4 mb-md-0 mb-3" onClick={() => navigate("/resource/detail", {
                                                            state: {
                                                                id: item.resource_id,
                                                                tab_index: null,
                                                                page: 1
                                                            }
                                                        })}>
                                                            <ResourceCard data={item} />
                                                        </div>
                                                    )
                                                })}

                                            </section>
                                        </Fragment>}
                                        {
                                            (homePageData.resource !== null && homePageData.resource.length > 0) &&
                                            <section className="row margin-32px-top d-md-none d-block">
                                                {/* <Slider slidesToShow={1}
                                                    slidesToScroll={1}
                                                    autoplaySpeed={3000}
                                                    // autoplay={true}
                                                    dots={false}
                                                    arrows={false}

                                                    dotsClass={"slick-dots e-words-slider"}> */}
                                                {
                                                    homePageData.resource.map((item, key) =>
                                                        <div className="col-12 mb-3"
                                                            key={key}
                                                            onClick={() => navigate("/resource/detail", {
                                                                state: {
                                                                    id: item.resource_id,
                                                                    tab_index: null,
                                                                    page: 1
                                                                }
                                                            })}>
                                                            <ResourceCard data={item} />
                                                        </div>
                                                    )
                                                }
                                                {/* </Slider> */}
                                            </section>
                                        }

                                        {/* Begin investing */}
                                        {
                                            // eslint-disable-next-line
                                            homePageData.banner_data.map((item) => {
                                                if (item.content_type === 3) {
                                                    return (
                                                        <section className="margin-40px-top">
                                                            <SectionTitle label="Believe It Or Not!"
                                                                className="mb-md-4 mb-3" />
                                                            <img src={item.image_url} alt=""
                                                                className="img-fluid cursor-pointer"
                                                                onClick={() => _handleBannerNavigate(item)} />

                                                        </section>
                                                    )
                                                }
                                            })
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>


                        {
                            showSuccess &&
                            <KYCConfirmationModal type={1}
                                label="Dashboard"
                                showButton={true}
                                title="Congratulations"
                                content="Your account has been successfully created. Explore the funds, goals, and start your first investment now."
                                closeModal={() => _confirmInvestment(false)}
                                submitInvestment={() => _confirmInvestment(true)}
                                handleNavigate={() => navigate("/")} />
                        }

                        {
                            showPreview === true &&
                            <VideoPreviewModal url='https://mintit-prod-static-assets.s3.ap-south-1.amazonaws.com/videos/Mint_it_bridges_edited_1809.mp4'
                                type={1}
                                size={450}
                                closeModal={() => {
                                    _showPreview(false);
                                }} />
                        }

                        {
                            esignModal === true &&
                            <EsignCreateModal
                                closeModal={() => _initiateEsign(false)}
                                submitInvestment={() => _confirmInvestment(true)}
                                reSubmit={() => _initiateEsign(false)} />
                        }

                        {
                            importFunds === true &&
                            <ImportExternalFundsModal
                                showButton={true}
                                label="View Portfolio"
                                content={"Funds will appear on your dashboard in 10-15 mins. We will notify you once it is done."}
                                closeModal={() => _handleImport(false)}
                                onSuccessfulImport={() =>
                                    navigate("/portfolio", {
                                        state: {
                                            fund_type: 2,
                                        }
                                    })
                                }
                            />
                        }
                    </Fragment>
            }
        </>

    )
}

export default Home;
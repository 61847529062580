/*
*   File : configure-pin.js
*   Author : https://evoqins.com
*   Description : Configure PIN Container
*   Version : 1.0.0
*/
// import packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom/dist";
import Cookies from 'js-cookie';

// import components
import { CustomHeader } from "../../Components/Navigation";
import { CustomPINInput } from "../../Components/FormElements";
import { AuthSlider } from "../../Components/Slider";
import { PrimaryButton } from "../../Components/Buttons";
import { Icon } from "../../Components/Icon";


// import helper
import APIService from "../../Services/api-service";

import store from "../../Store";

const ConfigurePIN = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [pin, setPin] = useState("");
    const [pinError, setPinError] = useState("");

    const [confirmPin, setConfirmPin] = useState("");
    const [confirmPinError, setConfirmPinError] = useState("");

    const [tempToken, setTempToken] = useState("");

    const [isFocused, setIsFocused] = useState(false);

    const [firstIsFocused, setFirstIsFocused] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);

    const [isAuthConfigured, setIsAuthConfigured] = useState(false);

    const [refreshToken, setRefreshToken] = useState("");

    useEffect(() => {
        if (location.state !== null) {
            setIsAuthConfigured(location.state.configured);
            setRefreshToken(location.state.refresh_token);
        }
    }, [location.state]);

    useEffect(() => {
        if (location.state !== null) {
            setTempToken(location.state.temp_token);
        }
    }, [location.state])


    function _handlePIN(input_value) {
        if (input_value.length <= 4) {
            setPin(input_value);
        }
        if (input_value.length > 3) {
            setIsFocused(true);
            setFirstIsFocused(false);
        }
        setPinError("");
    }

    function _handleConfirmPIN(input_value) {
        if (input_value.length <= 4) {
            setConfirmPin(input_value);
        }
        if (input_value.length === 0) {
            setIsFocused(false);
            setFirstIsFocused(true);
        }
        setConfirmPinError("");
    }


    function _submitPIN() {
        let valid = true;

        if (pin === "") {
            setPinError("PIN cannot be empty");
            valid = false;
        }
        if (pin !== "" && pin.length !== 4) {
            setPinError("Enter a valid PIN");
            valid = false;
        }

        if (confirmPin === "") {
            setConfirmPinError("Confirm PIN is cannot be empty");
            valid = false;
        }

        if (confirmPin !== "" && confirmPin.length !== 4) {
            setConfirmPinError("Invalid PIN");
            valid = false;
        }
        if (confirmPin.length === 4 && pin !== confirmPin) {
            setConfirmPinError("Please enter a matching pin number");
            valid = false;
        }

        if (valid === true) {
            if (isAuthConfigured) {
                _setPIN();
            } else {
                _resetPIN();
            }
        }
    }

    // API - set pin to call when user comes from signup
    const _setPIN = async () => {
        setApiLoader(true);
        const url = "/auth/set-pin"
        const request = {
            "pin": pin
        }
        await APIService(true, url, request, refreshToken).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("PIN set successfully", {
                    type: "success",
                });
                navigate("/unlock", { replace: true });
                Cookies.set("mintit_refresh_token", response.data.refresh_token, {
                    expires: 90,
                    domain: process.env.REACT_APP_DOMAIN,
                    path: '/'
                });
                store.dispatch({
                    type: "REFRESH_TOKEN",
                    payload: response.data.refresh_token
                })

            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
            setApiLoader(false);
        })
    }


    // API - reset pin to call when user comes from forgot-pin
    const _resetPIN = async () => {
        setApiLoader(true);
        const url = "/auth/reset-pin"
        const request = {
            "pin": confirmPin,
            "temp_token": tempToken,
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("PIN Reset successfully", {
                    type: "success",
                });
                navigate("/unlock", { replace: true });
                Cookies.set("mintit_refresh_token", response.data.refresh_token, {
                    expires: 90,
                    domain: process.env.REACT_APP_DOMAIN,
                    path: '/'
                });
                store.dispatch({
                    type: "REFRESH_TOKEN",
                    payload: response.data.refresh_token
                })
            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
            setApiLoader(false);
        })
    }
    return (
        <Fragment>
            <CustomHeader />
            <div className="padding-96px-top d-flex flex-column align-items-center justify-content-end">
                <div className="e-login-wrapper border-radius-32px">
                    <div className="row">
                        <div className="col-lg-5 col-md-5">
                            <AuthSlider />
                        </div>
                        <div className="col-lg-1 col-md-1">
                            <div className="e-login-seperator position-relative"></div>
                        </div>
                        <div className="col-lg-6 col-md-6 padding-66px-top">
                            <Fragment>
                                <h3 className="color-dark-jungle-green mb-1 e-font-24 e-line-height-36 e-montserrat-semi-bold letter-spacing-minus2em">
                                    Set up your MINTIT
                                </h3>
                                <p className="color-blue-gray e-font-14 e-line-height-24 e-montserrat-regular letter-spacing-minus2em mb-3">
                                    Protect your Investment Journey by setting up a PIN.
                                </p>

                                <CustomPINInput id="pin-input"
                                    pinString={pin}
                                    autoFocus={firstIsFocused}
                                    error={pinError}
                                    className='mb-4'
                                    onSubmit={() => _submitPIN(true)}
                                    title="Enter your pin"
                                    postfix="*"
                                    handleChange={_handlePIN} />

                                <CustomPINInput id="reset-pin-input"
                                    pinString={confirmPin}
                                    title="Re-enter your pin"
                                    postfix="*"
                                    autoFocus={isFocused}
                                    error={confirmPinError}
                                    onSubmit={() => _submitPIN(false)}
                                    handleChange={_handleConfirmPIN} />

                                <PrimaryButton className="w-100 margin-32px-top padding-10px-tb"
                                    label="Continue"
                                    disabled={apiLoader}
                                    onPress={() => _submitPIN(false)} />
                            </Fragment>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-center mt-4 gap-10px padding-32px-bottom">
                    <p className="color-charcoal-gray e-font-16 e-line-height-28 e-montserrat-semi-bold mb-0">Download App</p>
                    <div className="d-flex align-items-center padding-10px-all gap-10px cursor-pointer e-social-icon" onClick={() => window.open("https://apps.apple.com/app/mintit/id6737547969", "_blank")}>
                        <Icon icon='ios-store'
                            size={28} />
                        <p className="color-charleston-green e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">AppStore</p>
                    </div>
                    <div className="d-flex align-items-center padding-10px-all ps-0 gap-10px cursor-pointer e-social-icon" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.mintit.in", "_blank")}>
                        <Icon icon='play-store'
                            size={28} />
                        <p className="color-charleston-green e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">PlayStore</p>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default ConfigurePIN
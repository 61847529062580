/*
*   File : profile.js
*   Author : https://evoqins.com
*   Description : Screen to show details filled during the account creation time.
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader } from "../../Components/Other";
import { GaugeChart } from "../../Components/Charts";
import { PrimaryButton } from "../../Components/Buttons";
import { RiskAssessmentReview } from "../../Components/Modal";
import { Icon } from "../../Components/Icon";
import { DeleteAccountModal } from "../../Components/Modal";

const Profile = () => {
    const PROFILE_DATA = useSelector((state) => state.Reducer.PROFILE_DETAILS);

    const navigate = useNavigate();

    const [apiLoader, setApiLoader] = useState(true);
    const [showRiskModal, setShowRiskModal] = useState(false);
    const [showDeleteAccount, setShowDeleteAccount] = useState(false);

    useEffect(() => {
        if (Object.keys(PROFILE_DATA).length !== 0) {
            setApiLoader(false);
        }
    }, [PROFILE_DATA])

    useEffect(() => {
        if (showRiskModal === true) {
            const modal = new Modal(document.getElementById('risk-assessment'));
            modal.show();
        }
    }, [showRiskModal]);

    useEffect(() => {
        if (showDeleteAccount === true) {
            const modal = new Modal(document.getElementById('delete-profile'));
            modal.show();
        }
    }, [showDeleteAccount]);

    function _handleNavigate() {
        navigate("/");
    }

    const _handleRisk = (status) => {
        setShowRiskModal(status);
    }

    const _showDeleteAccount = (status) => {
        setShowDeleteAccount(status);
    }

    const InfoDetail = (props) => {
        return (
            <Fragment>
                <p className="e-montserrat-medium e-font-14 e-line-height-24 color-outer-space mb-1">{props.heading}</p>
                <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black mb-0">{props.info === null ? "-" : props.info}</p>
            </Fragment>
        )
    }

    return (
        apiLoader === true ?
            <Loader height="h-80vh" />
            :
            <Fragment>
                <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                    {/* Header */}
                    <Header type={2} />
                    <div className="row ">
                        <div className="col-lg-11 pe-lg-0 padding-144px-bottom">

                            <div className="px-4">
                                <Breadcrumb mainPage="Home"
                                    currentPage="Profile"
                                    handleNavigate={_handleNavigate}
                                    type={1} />
                                <p className="e-montserrat-semi-bold e-font-18 e-line-height-38 color-dark-jungle-green margin-12px-bottom margin-30px-top">Profile</p>
                                <div className="row row-gap-24px">
                                    <div className="col-lg-8">
                                        <div className="border-radius-24px e-profile-border">
                                            <div className="p-4 e-bg-light-lavender border-radius-tlr-24px d-flex align-items-center gap-12px">
                                                <span className="e-montserrat-semi-bold e-font-34 e-line-height-36 color-lavender-white padding-8px-all e-bg-eminence border-radius-8px">{PROFILE_DATA.short_name}</span>
                                                <div>
                                                    <p className="e-montserrat-semi-bold e-font-18 e-line-height-20 color-eerie-black mb-2">{PROFILE_DATA.name}</p>
                                                    <p className="e-montserrat-medium e-font-16 e-line-height-22 color-rebecca-purple mb-0"><span className="color-outer-space">User ID:</span> {PROFILE_DATA.customer_id}</p>
                                                </div>
                                            </div>
                                            <div className="p-4 border-top-1px border-platinum">
                                                <p className="e-montserrat-semi-bold e-font-16 e-line-height-30 color-eerie-black">Personal Details</p>
                                                <div className="row row-gap-8px mb-sm-4 mb-2">
                                                    <div className="col-md-4 col-sm-6">
                                                        <InfoDetail heading="Gender"
                                                            info={PROFILE_DATA.gender} />
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <InfoDetail heading="Marital Status"
                                                            info={PROFILE_DATA.marital_status} />
                                                    </div>
                                                </div>
                                                <div className="row row-gap-8px">
                                                    <div className="col-md-4 col-sm-6">
                                                        <InfoDetail heading="Born On"
                                                            info={PROFILE_DATA.dob} />
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <InfoDetail heading="Account Opened"
                                                            info={PROFILE_DATA.created} />
                                                    </div>
                                                </div>
                                                <p className="e-montserrat-semi-bold e-font-16 e-line-height-30 color-eerie-black mt-4 pt-3 border-top-1px border-platinum">Contact Details</p>
                                                <div className="row row-gap-24px mb-4">
                                                    <div className="col-md-4 col-sm-6">
                                                        <p className="e-montserrat-medium e-font-14 e-line-height-24 color-outer-space mb-1">Email</p>
                                                        <a href={`mailto:${PROFILE_DATA.email}`}
                                                            rel="noreferrer"
                                                            className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black mb-0 text-decoration-none text-transform-lower">{`${PROFILE_DATA.masked_email}`}</a>
                                                        <Icon icon="verified" size={16}
                                                            className="ms-1" />
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <p className="e-montserrat-medium e-font-14 e-line-height-24 color-outer-space mb-1">Mobile</p>
                                                        <a href={`tel:${PROFILE_DATA.phone.country_code}${PROFILE_DATA.phone.masked_number}`}
                                                            rel="noreferrer"
                                                            className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black mb-0 text-decoration-none">{`${PROFILE_DATA.phone.country_code}-${PROFILE_DATA.phone.masked_number}`}</a>
                                                        <Icon icon="verified" size={16}
                                                            className="ms-1" />
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <InfoDetail heading="PAN Number"
                                                            info={PROFILE_DATA.masked_pan} />
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <InfoDetail heading="Aadhar Number"
                                                            info={PROFILE_DATA.masked_aadhaar} />
                                                    </div>
                                                </div>
                                                <div className="row row-gap-24px">
                                                    {PROFILE_DATA.sign_proof !== null && <div className="col-md-4 col-sm-6">
                                                        <p className="e-montserrat-medium e-font-14 e-line-height-24 color-outer-space mb-1">Signature</p>
                                                        <div className="border-radius-12px e-bg-cultured">
                                                            <img src={PROFILE_DATA.sign_proof} alt="Signature"
                                                                className="w-100 h-77px object-fit-contain" />
                                                        </div>
                                                    </div>}
                                                    {PROFILE_DATA.address !== null && <div className="col-sm-6">
                                                        <InfoDetail heading="Address"
                                                            info={`${PROFILE_DATA.address.address_line1}, ${PROFILE_DATA.address.address_line2 ? `${PROFILE_DATA.address.address_line2},` : ""} ${PROFILE_DATA.address.city}, ${PROFILE_DATA.address.state}, ${PROFILE_DATA.address.pincode}, ${PROFILE_DATA.address.country}`} />
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {PROFILE_DATA.risk_assessment_done === true && <div className="col-lg-4">
                                        <div className="border-radius-24px e-profile-border p-4">
                                            <p className="e-montserrat-semi-bold e-font-16 e-line-height-30 color-eerie-black margin-28px-bottom">Current Risk Score</p>
                                            <div className="d-flex justify-content-center position-relative">
                                                <GaugeChart value={PROFILE_DATA.risk_score !== null ? PROFILE_DATA.risk_score.score * 10 - 4 : 0}
                                                    show_labels={false}
                                                    risk_score={true} />
                                                <div className="position-absolute  top-50-percent text-center">
                                                    <span className="e-font-14 e-line-height-20 e-montserrat-regular color-black-olive" >
                                                        Risk score
                                                    </span>
                                                    <h6 className="e-font-32 e-line-height-42 e-montserrat-semi-bold color-dark-jungle-green  mb-0" >{PROFILE_DATA.risk_score !== null ? PROFILE_DATA.risk_score.score : "-"}</h6>
                                                    <p className={`e-montserrat-medium e-font-14 e-line-height-24 ${PROFILE_DATA.investment_style_text === "Conservative" ? "color-kelly-green" : PROFILE_DATA.investment_style_text === "Moderate" ? "color-metallic-yellow" : PROFILE_DATA.investment_style_text === "Aggressive" ? "color-tangerine" : "color-pastel-red"}`}>{PROFILE_DATA.investment_style_text}</p>

                                                </div>
                                            </div>
                                            <p className="e-montserrat-regular e-font-16 e-line-height-28 color-jet text-center mt-3 margin-40px-bottom">{PROFILE_DATA.risk_score !== null ? PROFILE_DATA.risk_score.score_text : "-"}</p>
                                            <div className="d-flex justify-content-center">
                                                <PrimaryButton className="padding-12px-tb padding-24px-lr border-radius-12px"
                                                    label="Re-assess Risk profile"
                                                    onPress={() => _handleRisk(true)} />
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-8">
                                        <div className="d-flex align-items-center cursor-pointer gap-8px"
                                            onClick={() => _showDeleteAccount(true)}>

                                            <div className="d-flex justify-content-between align-items-center border-tb-1px border-platinum w-100 py-3">
                                                <div className="d-flex align-items-center gap-8px">
                                                    <Icon icon="delete-account"
                                                        size={24} />
                                                    <p className=" e-montserrat-medium e-font-16 e-line-height-20 color-sunset-orange mb-0">Delete Account</p>
                                                </div>
                                                <Icon icon="arrow-right"
                                                    size={24}
                                                    className="opacity-4" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showRiskModal === true && <RiskAssessmentReview closeModal={() => _handleRisk(false)} />
                }
                {
                    showDeleteAccount === true && <DeleteAccountModal closeModal={() => _showDeleteAccount(false)} />
                }
            </Fragment>
    )
}

export default Profile
/*
 *   File : consent.js
 *   Author : https://evoqins.com
 *   Description : Consent card component
 *   Integrations : null
 *   Version : 1
*/

import { Fragment, useState } from "react"
import { Icon } from "../Icon";


const ConsentCard = (props) => {
    const [showMore, setShowMore] = useState(false);

    function _handleToggle() {
        setShowMore(!showMore)
    }
    return (
        <Fragment>
            <div className="d-flex align-items-start gap-8px mt-3 w-fit-content text-start p-1" >
                <span className={props.error === true ? "e-error-svg" : ""} onClick={() => props.setAcceptedTerms()}>
                    <Icon icon={props.acceptedTerms ? "checked" : "unchecked"}
                        size={18}
                        className={"cursor-pointer border-color-purple"} />
                </span>
                {
                    props.type === 2 ?
                        showMore ?
                            <p className="mb-0 e-montserrat-medium e-font-12 e-line-height-20 letter-spacing-007em ">
                                I/We have read and understood the contents of the Offer Document(s), Load Structure(s) of the respective Scheme(s) and agree to abide by the terms, conditions, rules and regulations of the respective Scheme(s).
                                <br /> The ARN holder has disclosed to me/us all the <a href="https://mintit.in/disclosure" target="_blank" rel="noreferrer" className="e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple  e-link">commissions</a> in the form of trail commission or any other mode), payable to him for the different competing Schemes of various Mutual Funds from amongst which the Scheme is being recommended to me/us.
                                <br />Disclaimer: The Registrar of the AMC will allot the number of units for the total investment amount. The number of Units allotted by the Registrar will reflect in your online account statement within 2 working days <span className="e-font-12 e-line-height-20 cursor-pointer e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple  d-inline-block"
                                    onClick={_handleToggle}>View less</span>
                            </p>
                            :
                            <p className="mb-0 e-montserrat-medium e-font-12 e-line-height-20 letter-spacing-007em">
                                I/We have read and understood the contents of the Offer Document(s), Load Structure(s) of the respective Scheme(s) and agree to abide by the terms, conditions, rules and regulations of the...
                                <span className="e-font-12 e-line-height-20 cursor-pointer e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple d-inline-block"
                                    onClick={_handleToggle}>View more</span>
                            </p>
                        :
                        showMore ?
                            <p className="mb-0 e-montserrat-medium e-font-12 e-line-height-20 letter-spacing-007em ">
                                I/We have read and understood the <a href="https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=3" target="_blank" rel="noreferrer" className="e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple  e-link"
                                >KIM</a> and <a href="https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=2" target="_blank" rel="noreferrer" className="e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple  e-link"
                                >SID</a> and contents of the Offer Document(s), Load Structure(s) of the respective Scheme(s) and agree to abide by the terms, conditions, rules and regulations of the respective Scheme(s).
                                <br />{props.risk ? null : <span>The ARN holder has disclosed to me/us all the <a href="https://mintit.in/disclosure" target="_blank" rel="noreferrer" className="e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple  e-link">commissions</a> in the form of trail commission or any other mode), payable to him for the various Mutual Funds.
                                    < br />
                                    Disclaimer: The Registrar of the AMC will allot the number of units for the total investment amount. The number of Units allotted by the Registrar will reflect in your online account statement within 2 working days. </span>} <span className="e-font-12 e-line-height-20 cursor-pointer e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple d-inline-block"
                                        onClick={_handleToggle}>View less</span>
                            </p>
                            :
                            props.type === 1 ?
                                <p className="mb-0 e-montserrat-medium e-font-12 e-line-height-20 letter-spacing-007em">
                                    I/We have read and understood the <a href="https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=3" target="_blank" rel="noreferrer" className="e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple  e-link"
                                    >KIM</a> and <a href="https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=2" target="_blank" rel="noreferrer" className="e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple  e-link"
                                    >SID</a> and contents of the Offer Document(s), Load Structure(s) of the respective Scheme(s) and agree to abide by the terms,...
                                    <span className="e-font-12 e-line-height-20 cursor-pointer e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple d-inline-block"
                                        onClick={_handleToggle}>View more</span>
                                </p>
                                :
                                <p className="mb-0 e-montserrat-medium e-font-12 e-line-height-20 letter-spacing-007em">
                                    I/We have read and understood the <a href="https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=3" target="_blank" rel="noreferrer" className="e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple  e-link"
                                    >KIM</a> and <a href="https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doMutualFund=yes&mftype=2" target="_blank" rel="noreferrer" className="e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple  e-link"
                                    >SID</a> and contents of the Offer Document...
                                    <span className="e-font-12 e-line-height-20 cursor-pointer e-font-12 e-line-height-20 e-montserrat-medium color-rebecca-purple d-inline-block"
                                        onClick={_handleToggle}>View more</span>
                                </p>
                }
            </div>

        </Fragment >
    )
}
export default ConsentCard

/*
 *   File : create-swp.js
 *   Author URI : https://evoqins.com
 *   Description :Modal to create swp order from portfolio
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, useEffect, useRef, useState } from "react";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Components
import { Icon } from "../Icon";
import { CustomAmountInput, CustomPINInput } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { SIPDateModal } from ".";
import EditableSlider from "../FormElements/editable-slider";

// Styles
import style from '../../Styles/Components/swp.module.scss';
import Color from '../../Styles/color.module.scss';

// Services, helper
import { _findNearestDate, _formatSip, _getTomorrowDate, useCountdown } from "../../Helper/helper";
import { _generate2Fa } from "../../Helper/api";
import APIService from "../../Services/api-service";

// Store
import store from "../../Store";
import { CustomDropdown } from "../Dropdown";

// Screen number 
// 1 :: Form elements-> Form elements min SWP amount, installments, date
// 2 :: Enter OTP
// 3 :: Success or Failure message


const SWPOrderModal = (props) => {
    const navigate = useNavigate();
    const PROFILE_DETAILS = store.getState().Reducer.PROFILE_DETAILS;
    const MASKED_NUMBER = PROFILE_DETAILS.phone.masked_number;
    const inputRef = useRef();
    const { seconds, resetCountdown } = useCountdown(30);

    const [screenNumber, setScreenNumber] = useState(1);
    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");
    const [apiLoader, setApiLLoader] = useState(false);

    const [selectedDate, setSelectedDate] = useState(_getTomorrowDate());
    const [selectedDateString, setSelectedDateString] = useState(_formatSip(_getTomorrowDate()));
    const [toggleDateModal, setToggleDateModal] = useState(false);

    const [investments, setInvestments] = useState(props.summary.default_swp_installments);
    const [swpDates, setSwpDates] = useState([]);

    const [modalData, setModalData] = useState({});

    const [folioOptions, setFolioOptions] = useState([]);
    const [folio, setFolio] = useState(null);
    const [folioError, setFolioError] = useState("");

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    useEffect(() => {
        var my_modal = document.getElementById("create-swp");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            document.body.removeAttribute('style')
        });
        //eslint-disable-next-line
    }, []);

    // Custom options
    useEffect(() => {
        const folio_options = props.summary.folio_data.map((item) => {
            return ({
                label: <div className="e-montserrat-medium" dangerouslySetInnerHTML={{ __html: `${item.folio_number} <br /> <span style="font-size:12px" class="e-montserrat-regular">Amount:</span> ₹${item.amount} ${item.units ? `<span style="font-size:12px" class="e-montserrat-regular">Units:</span> ${item.units}` : ``}` }} />,
                value: item.folio_number
            })
        });

        setFolioOptions(folio_options);
    }, [props.summary]);


    useEffect(() => {
        if (props.summary.swp_dates.length !== 0) {
            setSelectedDate(_findNearestDate(props.summary.swp_dates, selectedDate));

            const date_string = _formatSip(_findNearestDate(props.summary.swp_dates, selectedDate));
            setSelectedDateString(date_string);
            const swp_dates = []
            props.summary.swp_dates.forEach(element => {
                return (
                    swp_dates.push(`${element}`)
                )
            });
            setSwpDates(swp_dates)
        }
        // eslint-disable-next-line
    }, [props.summary])

    useEffect(() => {
        if (toggleDateModal === true) {
            const modal = new Modal(document.getElementById("sip-date"), {});
            modal.show();
        }
    }, [toggleDateModal]);

    function _handleInvestment(value) {
        setInvestments(value)
    }

    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // resend OTP
    function _handleResend() {
        setOtp("");
        setOtpError("");
        _generate2FaApi();
    }

    // navigate to SWP
    function _handleSWPNavigate() {
        navigate("/systematic-transactions", {
            state: {
                tab_index: 2,
            }
        })
    }

    // handle folio number
    function _handleFolio(select_value) {
        setFolio(select_value);
        setFolioError("");
    }

    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid) {
            _placeOrder()
        }
    }

    // API - resend otp
    const _generate2FaApi = () => {
        _generate2Fa("MF")
            .then((response) => {
                resetCountdown();
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };

    // handle sip date
    function _handleSelectDate(date, dateString) {
        setSelectedDate(date);
        setSelectedDateString(dateString)
    }

    function _handleAmount(input_value) {
        setAmount(input_value);
        setAmountError("");
    }

    // show sip date modal
    function _handleCloseModal(status) {
        setToggleDateModal(status);

    }

    function _handleSubmit() {
        let valid = true;
        if (amount === "") {
            inputRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
            setAmountError("Enter monthly SWP amount")
            valid = false
        }
        if (folio === null && props.fundType === 1) {
            inputRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
            setFolioError("Please select folio");
            valid = false;
        }
        if (valid === true) {
            _confirmSwp();
        }
    }

    // API - Create SWP
    const _confirmSwp = () => {
        setApiLLoader(true)
        const url = props.fundType === 2 ? "/external/confirm-swp" : "/mf/confirm-swp";
        const request = props.fundType === 2 ?
            {
                "id": props.id,
                "amount": amount,
                "installments": investments,
                "date": selectedDate,
            }
            :
            {
                "mf_id": props.summary.mf_id,
                "amount": amount,
                "installments": investments,
                "date": selectedDate,
                "folio_number": folio.value,
            }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setScreenNumber(2);
                setOtp("");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLLoader(false)
        })
    }

    const _placeOrder = () => {
        setApiLLoader(true)
        const url = props.fundType === 2 ? "/external/create-swp" : "/mf/create-swp";
        const request = props.fundType === 2 ?
            {
                "id": props.id,
                "amount": amount,
                "installments": investments,
                "date": selectedDate,
                "otp": otp
            }
            :
            {
                "mf_id": props.summary.mf_id,
                "amount": amount,
                "installments": investments,
                "date": selectedDate,
                "folio_number": folio.value,
                "otp": otp
            }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setScreenNumber(3);
                setModalData({
                    status: 1,
                    image: require("../../Assets/Images/Modal/success.png"),
                    title: "SWP Created Successful",
                    // description: "Lorem Ipsum has been the industry's standard dummy text ever since"
                })
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLLoader(false)
        })
    }


    return (
        <Fragment>
            <div className={`modal fade ${style.e_swp}`}
                id="create-swp"
                tabIndex="-1"
                aria-labelledby="create-swp"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content pb-4 ${style.e_content} `}>

                        {screenNumber !== 3 && <div className="border-bottom-1px border-ash-gray px-sm-4 px-3">
                            <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                {
                                    screenNumber === 1 &&
                                    <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                        SWP
                                    </h5>
                                }
                                {
                                    screenNumber === 2 &&
                                    <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                        <Icon icon="Arrow-Right"
                                            size={22}
                                            className="e-transform-left-arrow cursor-pointer me-2"
                                            onClick={() => setScreenNumber(1)} /> Enter OTP
                                    </h5>
                                }
                                <Icon icon="close-btn"
                                    size={24}
                                    color={Color.eerie_black}
                                    id="close-fund"
                                    className="cursor-pointer"
                                    data-bs-dismiss="modal" />
                            </div>
                        </div>}
                        <div className={`${style.e_height}`}>
                            {
                                screenNumber === 1 ?
                                    <Fragment>
                                        <div className="border-radius-12px padding-12px-all e-bg-magnolia mx-sm-4 mx-3 mt-3 e-fund-order">
                                            <div className="row position-relative z-index-2">
                                                <div className="col-10  position-relative z-index-2">
                                                    <h5 className="e-montserrat-semi-bold e-font-12 e-line-height-18 color-eerie-black mb-0">SWP (Systematic Withdrawal Plan)</h5>
                                                    <p className="e-montserrat-medium e-font-12 e-line-height-20 mt-1 color-jet-gray mb-0">
                                                        Withdraw a fixed amount of money every
                                                        month from a mutual fund.
                                                    </p>
                                                </div>

                                                <img src={require("../../Assets/Images/Portfolio/swp_image.png")}
                                                    alt="Swp"
                                                    className="object-fit-contain h-100  position-absolute top-0 end-0 w-auto z-index-1" />
                                            </div>
                                        </div>
                                        <div className="box-shadow-black p-3 mx-sm-4 mx-3  border-radius-16px mt-3 ">
                                            <p className="color-outer-space e-font-14 e-line-height-16 e-montserrat-regular margin-12px-bottom">Start SWP for this fund</p>
                                            <div className="d-flex align-items-center gap-8px">

                                                <img src={props.summary.icon}
                                                    alt="goal"
                                                    width={38}
                                                    height={38}
                                                    className="box-shadow-black border-radius-6px" />
                                                <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{props.summary.name}</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-2">
                                                <span className="color-outer-space e-font-14 e-line-height-16 e-montserrat-regular ">Available to withdraw</span>
                                                <h6 className="color-eerie-black e-font-16 e-line-height-32 e-montserrat-semi-bold mb-0">{props.summary.available_to_withdraw_text}</h6>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
                                                <div className="color-outer-space e-font-12 e-line-height-16 e-montserrat-regular">
                                                    Min SWP Amount
                                                    <h6 className="color-eerie-black e-font-14 e-line-height-16 e-montserrat-semi-bold mb-0">₹{props.summary.min_swp_amount}</h6>
                                                </div>

                                                <div className="color-outer-space e-font-12 e-line-height-16 e-montserrat-regular">
                                                    Units
                                                    <h6 className="color-eerie-black e-font-14 e-line-height-16 e-montserrat-semi-bold mb-0">{props.summary.available_to_withdraw_units}</h6>
                                                </div>
                                            </div>
                                            <p className="color-outer-space e-font-12 e-line-height-18 e-montserrat-medium">*Exit load and capital gain tax may be applicable</p>
                                        </div>
                                        <div className="px-sm-4 px-3" ref={inputRef}>
                                            <h5 className='e-font-14  color-eerie-black e-montserrat-semi-bold e-line-height-24 my-3'>
                                                Create SWP
                                            </h5>

                                            <CustomAmountInput label="Monthly SWP amount"
                                                postfix={"*"}
                                                value={amount}
                                                error={amountError}
                                                placeholder="50,000"
                                                handleChange={_handleAmount} />

                                            {props.fundType === 1 && <CustomDropdown label="Folio"
                                                postfix="*"
                                                options={folioOptions}
                                                value={folio}
                                                placeholder="Select Folio"
                                                className="mt-4 pt-1 pb-4"
                                                isSingleValue={true}
                                                error={folioError}
                                                message="No folios found"
                                                onSelectChange={_handleFolio} />}
                                            {/* <div className="d-flex align-items-start padding-12px-all mt-4 e-bg-light-blue border-radius-12px my-4 color-ocean-blue e-font-14 e-line-height-20 gap-4px e-montserrat-medium">
                                                <span>
                                                    <Icon icon="info-circle"
                                                        size={16} />
                                                </span>
                                                Ideal SWP amount should be 8% p.a. of Invested amount
                                            </div> */}
                                            <EditableSlider label="No. of installments"
                                                className="mt-1"
                                                edit={false}
                                                value={investments}
                                                min={props.summary.min_swp_installments}
                                                max={props.summary.max_swp_installments}
                                                step={1}
                                                // suffix={"Yrs"}
                                                // singularSuffix={"Yrs"}
                                                // pluralSuffix="Yrs"
                                                handleChange={_handleInvestment} />
                                        </div>
                                        <Fragment>

                                            <p className="e-font-14 e-line-height-24 e-montserrat-medium color-eerie-black mb-2 mx-sm-4 mx-3 mt-4">
                                                Select SWP date
                                            </p>
                                            {/* SIP component */}
                                            <div className="padding-14px-tb px-3 bg-white box-shadow-charleston border-1px border-light-grayish-blue d-flex justify-content-between align-items-center border-radius-12px cursor-pointer mx-sm-4 mx-3 mb-3"
                                                onClick={() => _handleCloseModal(true)}>
                                                <span className="color-rebecca-purple e-font-14 e-montserrat-semi-bold e-line-height-18">{selectedDateString} of every month</span>
                                                <Icon icon="dob"
                                                    size={24} />

                                            </div>

                                        </Fragment>
                                    </Fragment>
                                    :
                                    screenNumber === 2 ?
                                        <div className="px-sm-4 px-3 padding-14px-top">
                                            <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                                An OTP has been sent to your PAN linked mobile
                                                number.
                                            </p>
                                            <h6 className="e-font-16 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">{MASKED_NUMBER}</h6>
                                            <CustomPINInput id="otp"
                                                autoFocus={true}
                                                pinString={otp}
                                                error={otpError}
                                                postfix="*"
                                                label="Enter OTP"
                                                onSubmit={_submitOTP}
                                                handleChange={_handleOTP} />
                                            <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link 
                                ${seconds === 0 ?
                                                    'cursor-pointer'
                                                    : 'disabled'}`}
                                                onClick={_handleResend}>
                                                {seconds === 0 ?
                                                    'Resend OTP'
                                                    : `Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}
                                            </span>
                                            </p>

                                        </div>
                                        :
                                        <div className="text-center px-sm-4 px-3">
                                            <img src={modalData.image}
                                                alt={modalData.title}
                                                draggable={false}
                                                width={144}
                                                height={144}
                                                className="mx-auto" />
                                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-0">
                                                {modalData.title}
                                            </h5>

                                            {/* <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mt-2 mb-0 ">
                                                {modalData.description}
                                            </p> */}
                                        </div>
                            }
                        </div>
                        {
                            screenNumber === 1 ?
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <PrimaryButton label="Confirm SWP" className="mt-3 mx-auto w-100"
                                            disabled={apiLoader}
                                            onPress={_handleSubmit} />
                                    </div>
                                </div>
                                :
                                screenNumber === 2 ?
                                    <div className="px-sm-4 px-3 mt-3">
                                        <div className="row">
                                            <div className="col-6">
                                                <SecondaryButton label="Cancel"
                                                    cancel="modal"
                                                    className="padding-12px-tb w-100 e-bg-magnolia" />
                                            </div>
                                            <div className="col-6">
                                                <PrimaryButton label="Verify"
                                                    className="padding-12px-tb  w-100"
                                                    disabled={apiLoader}
                                                    onPress={_submitOTP} />
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <div className="row justify-content-center px-sm-4 px-3">
                                        <div className="col-lg-6">
                                            <SecondaryButton label="View SWP"
                                                className="w-100 padding-12px-tb mt-3"
                                                cancel="modal"
                                                onPress={_handleSWPNavigate} />
                                        </div>

                                        <div className="col-lg-6">
                                            <PrimaryButton label="Portfolio"
                                                className="w-100 padding-12px-tb mt-3"
                                                cancel="modal"
                                                onPress={() => navigate("/portfolio")}
                                            />
                                        </div>
                                    </div>
                        }
                    </div>
                </div>
                {
                    toggleDateModal &&
                    <Fragment>
                        <SIPDateModal apiDates={props.summary.swp_dates !== null ? swpDates : []}
                            selectedSIPDate={selectedDate}
                            handleSelect={_handleSelectDate}
                            backdrop={!toggleDateModal}
                            title="SWP"
                            handleCloseDateModal={() => _handleCloseModal(false)} />
                        <div className="position-fixed bg-black opacity-1 w-100 h-100 z-index-4 top-0 start-0">
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment >
    )
}

SWPOrderModal.defaultProps = {
    fundType: 1
}

export default SWPOrderModal
/*
*   File : detail.js
*   Author : https://evoqins.com
*   Description : Container to list the detail page of the invested instrument from the portfolio page. 
*   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { Icon } from "../../Components/Icon";
import { Breadcrumb, Loader } from "../../Components/Other";
import { Header } from "../../Components/Header";
import { CustomTabBar } from "../../Components/Tab";
import { CustomAmountInput, CustomTextInput, RadioGroup } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";
import { HorizontalStackedBar, PieChart } from "../../Components/Charts";
import { CreateSTPOrder, FundInvestModal, FundRedemptionModal, GoalRedemptionModal, InvestMoreModal, SwitchOrderModal, SWPOrderModal } from "../../Components/Modal";

// Services
import APIService from "../../Services/api-service";
import { _findNearestDate, _formatSip, _getTomorrowDate } from "../../Helper/helper";

const TAB_DATA = [
    {
        id: 1,
        label: "Invest More"
    },
    {
        id: 2,
        label: "Redeem"
    }
]

const AMOUNT_CHIP = [{
    id: 1,
    label: "+₹10,000",
    amount: 10000
},
{
    id: 2,
    label: "+₹20,000",
    amount: 20000
},
{
    id: 3,
    label: "+₹50,000",
    amount: 50000
}]


const INVESTMENT_TYPES =
    [
        {
            id: 1,
            type: "Switch Fund",
            icon: require("../../Assets/Images/Portfolio/switch.png"),
        },
        {
            id: 2,
            type: "SWP",
            icon: require("../../Assets/Images/Portfolio/swp.png"),
        },
        {
            id: 3,
            type: "STP",
            icon: require("../../Assets/Images/Portfolio/stp.png"),
        }
    ]

const REDEMPTION_TYPES = [

    {
        name: "Amount",
        id: 2
    },
    {
        name: "Units",
        id: 1
    },
];


const PortfolioDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // eslint-disable-next-line
    const [pageLoader, setPageLoader] = useState(true);
    const [selectedTab, setSelectedTab] = useState(TAB_DATA[0].id);
    const [amount, setAmount] = useState("");
    const [unit, setUnit] = useState("");
    const [redeem, setRedeem] = useState("");
    const [portfolioDetail, setPortfolioDetail] = useState({});
    const [graphData, setGraphData] = useState([]);
    const [reInvestData, setReInvestData] = useState({});
    const [investMore, setInvestMore] = useState(false);
    const [goalRedeemData, setGoalRedeemData] = useState(null);
    const [mfRedeemData, setMfRedeemData] = useState(null);


    const [apiLoader, setApiLoader] = useState(false);
    const [pieData, setPieData] = useState({});

    const [selectedDate, setSelectedDate] = useState(_getTomorrowDate());
    const [selectedDateString, setSelectedDateString] = useState(_formatSip(_getTomorrowDate()));

    const [fundInvestMore, setFundInvestMore] = useState(false);
    const [fundType, setFundType] = useState(1);

    const [summaryLoader, setSummaryLoader] = useState(true)

    // const [updateInvestMore, setUpdateInvestMore] = useState(false);

    const [otherInvestmentModal, setOtherInvestmentModal] = useState(null);
    const [redemptionType, setRedemptionType] = useState(REDEMPTION_TYPES[0].id);


    useEffect(() => {
        if (goalRedeemData !== null) {
            const modal = new Modal(document.getElementById("goal-redemption"));
            modal.show();
        }
    }, [goalRedeemData]);

    useEffect(() => {
        if (mfRedeemData !== null) {
            const modal = new Modal(document.getElementById("fund-redeem"));
            modal.show();
        }
    }, [mfRedeemData]);

    useEffect(() => {
        if (otherInvestmentModal === 2) {
            const modal = new Modal(document.getElementById("create-swp"));
            modal.show();
        }
    }, [otherInvestmentModal]);

    useEffect(() => {
        if (otherInvestmentModal === 1) {
            const modal = new Modal(document.getElementById("create-switch"));
            modal.show();
        }
    }, [otherInvestmentModal]);

    useEffect(() => {
        if (otherInvestmentModal === 3) {
            const modal = new Modal(document.getElementById("create-stp"));
            modal.show();
        }
    }, [otherInvestmentModal]);

    useEffect(() => {
        if (location.state !== null) {
            _getPortfolioDetails(location.state.investment_type, location.state.id, location.state.fund_type);
            setFundType(location.state.fund_type);
        }
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        if (investMore === true) {
            const modal = new Modal(document.getElementById("invest-more"));
            modal.show();
        }
    }, [investMore]);

    useEffect(() => {
        if (fundInvestMore === true) {
            const modal = new Modal(document.getElementById("fund-invest"));
            modal.show();
        }
    }, [fundInvestMore]);


    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }

    function _handleOtherInvestment(id) {
        setOtherInvestmentModal(id)
    }


    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate(-1);
    }

    // handle Returns
    function _handleTabChange(value) {
        setSelectedTab(value);
    }

    // handle amount
    function _handleAmount(input_value) {
        setAmount(input_value);
    }

    function _handleRedeemAmount(input_value) {
        setRedeem(input_value)
    }

    // handle redemption type
    function _handleRedemptionType(id) {
        setRedemptionType(id);
    }

    // handle amount chip
    function _handleChipClick(item) {
        if (selectedTab === 1) {
            setAmount(prevAmount => (prevAmount !== "" ? prevAmount : 0) + item.amount);
        } else {
            setRedeem(prevAmount => (prevAmount !== "" ? prevAmount : 0) + item.amount);
        }
    }

    function _handleUnitChange(value) {

        // Regular expression to allow numbers and up to three decimal places
        const regex = /^\d*\.?\d{0,3}$/;

        // Check if the new unit matches the regex
        if (regex.test(value)) {
            if (value <= portfolioDetail.units) {
                setUnit(value);
            }
        }
    };

    // handler to open invest modal
    function _investMore(status) {
        setInvestMore(status);
    }

    // handle Redeem
    function _handleRedeem() {
        if (portfolioDetail.investment_type === 1) {
            _showGoalRedeemModal(portfolioDetail);
        } else if (portfolioDetail.investment_type === 3) {
            _showGoalRedeemModal(portfolioDetail);
        } else {
            _mfRedeem(portfolioDetail);
        }
    }


    // handler open modal
    function _showGoalRedeemModal(data) {
        setGoalRedeemData(data);
    }


    function _handleCloseMfRedeemModal() {
        setMfRedeemData(null);
    }
    function _handleCloseInvestMoreModal() {
        setFundInvestMore(false);
    }

    // API - ReInvest
    const _getInvestmentSuggestions = (mandate_check = false) => {
        setApiLoader(true);
        const url = "/goal/reinvestment-suggestion";

        const request = {
            goal_id: portfolioDetail.goal_id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setReInvestData(response.data);
                setSelectedDate(_findNearestDate(response.data.milestone_details.sip_dates, selectedDate));

                const date_string = _formatSip(_findNearestDate(response.data.milestone_details.sip_dates, selectedDate));
                setSelectedDateString(date_string);
                if (mandate_check === true) {
                    if (response.data.mandate_status === true) {
                        toast.success("Mandates Created Successfully", {
                            type: "success",
                        });
                    }
                    else {
                        toast.error("Mandate Creation Failed", {
                            type: "error",
                        });
                    }
                } else {
                    _investMore(true);
                }
            }
            setApiLoader(false);
            setSummaryLoader(false);
        })
    }

    // API - mf summary
    const _getMFSummary = (is_mandate = false) => {
        setApiLoader(true);
        const url = "/mf/summary";

        const request = {
            mf_id: portfolioDetail.mf_id
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {

                setReInvestData(response.data);

                if (response.data.sip_dates !== null) {
                    setSelectedDate(_findNearestDate(response.data.sip_dates, selectedDate));
                    const date_string = _formatSip(_findNearestDate(response.data.sip_dates, selectedDate));
                    setSelectedDateString(date_string);
                }

                if (is_mandate) {
                    if (response.data.mandate_status === true) {
                        toast.success("Mandates Created Successfully", {
                            type: "success",
                        });
                    } else {
                        toast.error("Mandate Creation Failed", {
                            type: "error",
                        });
                    }
                } else {
                    setFundInvestMore(true);
                }
            }
            setApiLoader(false);
            setSummaryLoader(false);

        });
    }


    // API -portfolio detail 
    const _getPortfolioDetails = (type, id, is_external) => {
        setPageLoader(true);
        const url = "/portfolio/detail";

        const request = {
            investment_type: type,
            id: id,
            is_external: is_external === 1 ? false : true
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setPortfolioDetail(response.data);

                if (type !== 2) {
                    const allocation_data = response.data.portfolio_data !== null && response.data.portfolio_data.graph_data.map((item) => {
                        return (
                            {
                                name: item.asset_class,
                                ...item
                            }
                        )
                    })
                    setGraphData(allocation_data);

                    setPieData({
                        labels: response.data.portfolio_data !== null && response.data.portfolio_data.graph_data.map((item) => item.name),
                        datasets: response.data.portfolio_data !== null && response.data.portfolio_data.graph_data.map((item) => item.allocation),
                        colors: response.data.portfolio_data !== null && response.data.portfolio_data.graph_data.map((item) => item.color)
                    })
                }
                setPageLoader(false);
            } else {
                _handleCurrentNavigate();
            }
        })
    }

    const _handleInvest = () => {
        if (portfolioDetail.investment_type === 2) {
            _getMFSummary();
        } else if (portfolioDetail.investment_type === 1) {
            _getInvestmentSuggestions();
        } else {
            _getInvestmentSuggestions();
        }
    }

    // API - redeem information
    const _mfRedeem = (row) => {
        const url = fundType === 2 ? "/external/redeem-overview" : "/mf/redeem-overview";
        const request = fundType === 2 ? {
            id: location.state.id,
        } : {
            mf_id: row.mf_id,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setMfRedeemData(response.data);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    // const _handleCloseUpdateModal = () => {
    //     setUpdateInvestMore(false);
    // }

    return (
        <Fragment>
            {
                pageLoader === true ?
                    <Loader height="h-80vh" />
                    :
                    <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                        <Header type={2} />
                        <div className="row ">
                            <div className="col-lg-11 pb-5">
                                <section className="px-4 ">
                                    <Breadcrumb mainPage="Home"
                                        currentPage="Portfolio"
                                        childPage={portfolioDetail.investment_type === 2 ? portfolioDetail.fund_name : portfolioDetail.name}
                                        type={2}
                                        handleParentNavigate={_handleParentNavigate}
                                        handleCurrentNavigate={_handleCurrentNavigate} />
                                    <Icon icon="Arrow-Right"
                                        size={32}
                                        className="mb-3 margin-32px-top e-transform-left-arrow cursor-pointer"
                                        onClick={_handleCurrentNavigate} />
                                    <div className="row row-gap-16px">
                                        <div className="col-xl-8 col-lg-10 col-md-10">
                                            <div className="box-shadow-black py-sm-4 py-3  border-radius-24px">
                                                <div className="d-flex align-items-center border-bottom-1px border-chinese-white pb-sm-4 pb-2 gap-8px px-sm-4 px-3">
                                                    <img src={portfolioDetail.investment_type === 2 ? portfolioDetail.image : portfolioDetail.icon}
                                                        alt="goal"
                                                        width={48}
                                                        className="box-shadow-black border-radius-12px d-sm-block d-none" />

                                                    <img src={portfolioDetail.investment_type === 2 ? portfolioDetail.image : portfolioDetail.icon}
                                                        width={38}
                                                        height={38}
                                                        alt="goal"
                                                        className="box-shadow-black border-radius-12px d-sm-none d-block" />
                                                    <div className="d-flex gap-8px flex-column">
                                                        <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{portfolioDetail.investment_type === 2 ? portfolioDetail.fund_name : portfolioDetail.investment_type === 1 ? `Milestone : ${portfolioDetail.name}` : portfolioDetail.name}</p>
                                                        {portfolioDetail.investment_type === 2 && <p className="e-montserrat-medium e-font-14 e-line-height-20 mb-0 color-outer-space"><span className="e-type position-relative me-3">{portfolioDetail.asset_class}</span>{portfolioDetail.fund_type}</p>}                                                    </div>

                                                </div>

                                                <div className="row row-gap-16px pt-md-4 pt-3 px-sm-4 px-3">
                                                    <div className="col-xl-6 col-md-8 col-sm-8 order-sm-1 order-2">

                                                        {
                                                            portfolioDetail.achieve &&
                                                            <div className="d-sm-block d-none">
                                                                <h6 className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black mb-0">Achieve</h6>
                                                                <p className={`color-emerald-green e-font-24  e-line-height-40 e-montserrat-semi-bold pt-sm-3 pt-0 mb-sm-3 mb-0`}>
                                                                    {portfolioDetail.target_amount_text} <span className="e-font-14 color-eerie-black e-montserrat-semi-bold">by {portfolioDetail.achieve.slice(-4)}</span>
                                                                </p>
                                                            </div>
                                                        }
                                                        {
                                                            portfolioDetail.investment_type === 2 &&
                                                            <p className={`e-font-24  e-line-height-40 e-montserrat-semi-bold mb-sm-3 mb-0 ${portfolioDetail.returns_percentage > 0 ? "color-go-green" : portfolioDetail.returns_percentage < 0 ? "color-deep-carmine-pink" : "color-eerie-black"}`}>
                                                                {portfolioDetail.returns_percentage_text} <span className="e-font-14 color-eerie-black e-montserrat-semi-bold">Returns</span>
                                                            </p>
                                                        }
                                                        <div className="row row-gap-8px">
                                                            <div className="col-sm-4 col-6">
                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Invested</p>
                                                                <p class="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{portfolioDetail.invested_amount_text}</p>
                                                            </div>
                                                            <div className="col-sm-4 col-6">
                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Current</p>
                                                                <p class="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{portfolioDetail.current_amount_text}</p>
                                                            </div>
                                                            <div className="col-sm-4 col-6">
                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">XIRR</p>
                                                                <p class={`e-montserrat-semi-bold e-font-14 e-line-height-20  mb-0 ${portfolioDetail.xirr > 0 ? "color-go-green" : portfolioDetail.xirr < 0 ? "color-deep-carmine-pink" : "color-eerie-black"}`}>{portfolioDetail.xirr_text}</p>
                                                            </div>
                                                            {portfolioDetail.investment_type !== 2 && <div className="col-sm-4 col-6 pt-sm-3 pt-0">
                                                                <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Returns</p>
                                                                <p class={`e-montserrat-semi-bold e-font-14 e-line-height-20  mb-0 ${portfolioDetail.returns_amount > 0 ? "color-go-green" : portfolioDetail.returns_amount < 0 ? "color-deep-carmine-pink" : "color-eerie-black"}`}>{portfolioDetail.returns_amount_text} <span className={` ${portfolioDetail.returns_percentage > 0 ? "color-go-green" : portfolioDetail.returns_percentage < 0 ? "color-deep-carmine-pink" : "color-eerie-black"} e-montserrat-medium`}>({portfolioDetail.returns_percentage_text})</span></p>
                                                            </div>}
                                                            {portfolioDetail.investment_type === 2 && <Fragment>
                                                                <div className="col-sm-4 col-6 pt-sm-3 pt-0">
                                                                    <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Units</p>
                                                                    <p class={`e-montserrat-semi-bold e-font-14 e-line-height-20  mb-0 color-eerie-black`}>{portfolioDetail.units} </p>
                                                                </div>
                                                                <div className="col-sm-4 col-6 pt-sm-3 pt-0">
                                                                    <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Cur. NAV</p>
                                                                    <p class={`e-montserrat-semi-bold e-font-14 e-line-height-20  mb-0 color-eerie-black`}>{portfolioDetail.nav} </p>
                                                                </div>
                                                                <div className="col-sm-4 col-6 pt-sm-3 pt-0">
                                                                    <p class="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Avg. NAV</p>
                                                                    <p class={`e-montserrat-semi-bold e-font-14 e-line-height-20  mb-0 color-eerie-black`}>{portfolioDetail.average_nav} </p>
                                                                </div>

                                                            </Fragment>}
                                                        </div>
                                                    </div>
                                                    {portfolioDetail.investment_type !== 2 && portfolioDetail.portfolio_data !== null && <div className="col-sm-6 order-sm-2 order-1">
                                                        {
                                                            portfolioDetail.investment_type !== 2 &&
                                                            <div className="d-sm-none d-block">
                                                                <h6 className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black mb-0">Achieve</h6>
                                                                <p className={`color-emerald-green e-font-24  e-line-height-40 e-montserrat-semi-bold pt-sm-3 pt-0 mb-sm-3 mb-0`}>
                                                                    {portfolioDetail.target_amount_text} <span className="e-font-14 color-eerie-black e-montserrat-semi-bold">by {portfolioDetail.tenure}</span>
                                                                </p>
                                                            </div>
                                                        }
                                                        <PieChart
                                                            achieved={((portfolioDetail.current_amount / portfolioDetail.xirr) * 100).toFixed(2)} data={pieData} />
                                                        <div className="d-flex justify-content-center flex-wrap gap-8px row-gap-8px">
                                                            {
                                                                pieData.datasets.map((item, index) => {
                                                                    return (
                                                                        <div className="d-flex align-items-center gap-8px">
                                                                            <div className="min-w-8px min-h-8px border-radius-100px" style={{ background: pieData.colors[index] }}></div>
                                                                            <span className="e-font-14 e-line-height-24 e-montserrat-medium">{pieData.labels[index]} - <span className="e-montserrat-semi-bold">{item}%</span></span>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>}
                                                    {portfolioDetail.folio_numbers !== null && portfolioDetail.investment_type === 2 && <div className="col-12 pt-sm-3 pt-0 order-sm-3 order-3">
                                                        <p class="e-montserrat-regular e-font-14 e-line-height-22 margin-2px-bottom color-outer-space">Folio number(s)</p>
                                                        <p class={`e-montserrat-semi-bold e-font-14 e-line-height-20  mb-0 `}>
                                                            {
                                                                portfolioDetail.folio_numbers.map((item, index) => `${item}${index < portfolioDetail.folio_numbers.length - 1 ? ", " : ""}`)
                                                            }
                                                        </p>
                                                    </div>}
                                                </div>
                                            </div>
                                            {portfolioDetail.active_sips !== null && <Fragment>
                                                <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black my-sm-4 mb-2 mt-4">
                                                    {portfolioDetail.active_sips.length === 1 ? "Active SIP" : "Active SIP's"}
                                                </h5>
                                                <div className="box-shadow-black p-sm-4 p-3 border-radius-24px">
                                                    <div className="d-flex justify-content-between align-items-center mb-sm-4 mb-3">
                                                        <span className="e-font-14 e-line-height-24 e-montserrat-semi-bold color-eerie-black">Amount & Start Date</span>
                                                        <span className="e-font-14 e-line-height-24 e-montserrat-semi-bold color-eerie-black">Upcoming SIP</span>
                                                    </div>
                                                    {portfolioDetail.active_sips.map((item, index) => {
                                                        return (
                                                            <div className={`d-flex justify-content-between align-items-center  ${index > 0 ? "border-top-1px border-onyx pt-sm-3 pt-2 mt-sm-3 mt-2" : ""}`}>
                                                                <div className="d-flex flex-column color-go-green e-font-20 e-line-height-24 letter-spacing-02em e-montserrat-semi-bold">
                                                                    {item.amount}
                                                                    <span className="e-font-14 e-montserrat-regular color-eerie-black e-line-height-18 mt-2">
                                                                        {item.start_date}
                                                                    </span>
                                                                </div>


                                                                <div className="border-1px border-bright-gray e-sip border-radius-14px px-sm-3 px-3 py-2  d-flex flex-column text-center me-4">
                                                                    <span className="color-rebecca-purple e-montserrat-bold e-font-20 e-line-height-20 margin-2px-bottom">{item.next_due.day}</span>
                                                                    <span className="color-eerie-black e-font-12 e-line-height-12 e-montserrat-medium">{item.next_due.month}</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Fragment>}
                                            {graphData.length > 0 && <>
                                                <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black my-sm-4 mb-2 mt-4">
                                                    Investment Allocation
                                                </h5>
                                                <div className="box-shadow-black px-sm-4 px-3 pt-sm-4 pt-3 border-radius-24px">
                                                    <h6 className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-3">Fund Breakdown</h6>
                                                    <div className="pb-sm-4 pb-2 border-bottom-1px border-chinese-white mb-sm-4 mb-3">
                                                        <HorizontalStackedBar allocations={graphData} />
                                                    </div>
                                                    <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold d-sm-none d-block mb-1">Funds ({graphData.length})</span>

                                                    <div className="row d-sm-flex d-none">
                                                        <div className="col-sm-6 pe-0">
                                                            <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Fund name</span>
                                                        </div>
                                                        <div className="col-sm-3 text-sm-center text-start">
                                                            <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Amount</span>
                                                        </div>
                                                        <div className="col-sm-3 text-end">
                                                            <span className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold">Weightage</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        portfolioDetail.portfolio_data.allocation_data !== undefined && portfolioDetail.portfolio_data.allocation_data.map((item, index) => {
                                                            return (
                                                                <div className={`py-sm-3 py-2 cursor-pointer ${index > 0 ? 'border-top-point5px border-onyx' : ''}`} onClick={() => navigate("/explore/detail", {
                                                                    state: {
                                                                        data: {
                                                                            mf_id: item.mf_id
                                                                        }
                                                                    }
                                                                })}>
                                                                    <div className={`row align-items-center`}>
                                                                        <div className="col-sm-6 pe-0">

                                                                            <div className="color-jet-gray e-font-14 e-line-height-18 e-montserrat-medium d-flex align-items-center gap-8px ">
                                                                                <img src={item.image}
                                                                                    width={42}
                                                                                    alt="goal"
                                                                                    className="box-shadow-black border-radius-12px d-sm-block d-none" />
                                                                                <img src={item.image}
                                                                                    width={38}
                                                                                    alt="goal"
                                                                                    className="box-shadow-black border-radius-12px d-sm-none d-block" />
                                                                                <span className="padding-2px-top">{item.name}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-3 col-6 text-sm-center text-start pt-sm-0 pt-2">
                                                                            <p className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold mb-0 d-sm-none d-block">Amount</p>
                                                                            <span className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-semi-bold">{item.amount}</span>
                                                                        </div>
                                                                        <div className="col-sm-3 col-6 text-end pt-sm-0 pt-2">
                                                                            <p className="color-black e-font-14 e-line-height-16 e-montserrat-semi-bold mb-0 d-sm-none d-block">Weightage</p>
                                                                            <span className="color-jet-gray e-font-14 e-line-height-14 e-montserrat-semi-bold">{item.allocation}%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </>}
                                            {portfolioDetail.investment_type === 2 && <div className="box-shadow-black border-radius-24px mt-sm-4 mt-3">

                                                {
                                                    portfolioDetail.switch_allowed === true && <div className={`d-flex justify-content-between align-items-center px-4 padding-20px-tb cursor-pointer`} onClick={() => _handleOtherInvestment(INVESTMENT_TYPES[0].id)}>
                                                        <div className="d-flex align-items-center gap-8px e-font-16 e-line-height-20 e-montserrat-medium letter-spacing-07em">
                                                            <img src={INVESTMENT_TYPES[0].icon}
                                                                alt={INVESTMENT_TYPES[0].type}
                                                                width={24} />
                                                            {INVESTMENT_TYPES[0].type}
                                                        </div>
                                                        <Icon icon="arrow-right"
                                                            size={20} />
                                                    </div>
                                                }

                                                {
                                                    portfolioDetail.swp_allowed === true && <div className={`d-flex justify-content-between align-items-center px-4 padding-20px-tb cursor-pointer border-top-1px border-gainsboro`} onClick={() => _handleOtherInvestment(INVESTMENT_TYPES[1].id)}>
                                                        <div className="d-flex align-items-center gap-8px e-font-16 e-line-height-20 e-montserrat-medium letter-spacing-07em">
                                                            <img src={INVESTMENT_TYPES[1].icon}
                                                                alt={INVESTMENT_TYPES[1].type}
                                                                width={24} />
                                                            {INVESTMENT_TYPES[1].type}
                                                        </div>
                                                        <Icon icon="arrow-right"
                                                            size={20} />
                                                    </div>
                                                }

                                                {
                                                    portfolioDetail.stp_allowed === true && <div className={`d-flex justify-content-between align-items-center px-4 padding-20px-tb cursor-pointer border-top-1px border-gainsboro`} onClick={() => _handleOtherInvestment(INVESTMENT_TYPES[2].id)}>
                                                        <div className="d-flex align-items-center gap-8px e-font-16 e-line-height-20 e-montserrat-medium letter-spacing-07em">
                                                            <img src={INVESTMENT_TYPES[2].icon}
                                                                alt={INVESTMENT_TYPES[2].type}
                                                                width={24} />
                                                            {INVESTMENT_TYPES[2].type}
                                                        </div>
                                                        <Icon icon="arrow-right"
                                                            size={20} />
                                                    </div>
                                                }

                                            </div>}
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6">
                                            {<div className="e-fund-detail-chart border-radius-24px position-sticky top-50px">
                                                <div className="border-bottom-1px border-chinese-white px-sm-4 px-3  padding-10px-top">
                                                    <CustomTabBar data={TAB_DATA}
                                                        selectedTab={selectedTab}
                                                        onSelectTab={_handleTabChange} />
                                                </div>
                                                <div className="px-sm-4 px-3">

                                                    <div className="box-shadow-black p-3 gap-8px border-radius-16px margin-20px-top d-flex align-items-center">
                                                        <img src={portfolioDetail.investment_type === 2 ? portfolioDetail.image : portfolioDetail.icon}
                                                            alt="goal"
                                                            width={48}
                                                            className="box-shadow-black border-radius-12px d-sm-block d-none" />

                                                        <img src={portfolioDetail.investment_type === 2 ? portfolioDetail.image : portfolioDetail.icon}
                                                            width={38}
                                                            height={38}
                                                            alt="goal"
                                                            className="box-shadow-black border-radius-12px d-sm-none d-block" />
                                                        <span className="padding-2px-top e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{portfolioDetail.investment_type === 2 ? portfolioDetail.fund_name : portfolioDetail.investment_type === 1 ? `Milestone : ${portfolioDetail.name}` : portfolioDetail.name}</span>
                                                    </div>
                                                    {
                                                        portfolioDetail.investment_type === 2 && selectedTab === 2 && < Fragment >
                                                            <h5 className='e-font-14 mb-1 mt-3 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                                                Redeem type
                                                            </h5>
                                                            <RadioGroup data={REDEMPTION_TYPES}
                                                                selected={redemptionType}
                                                                handleSelect={_handleRedemptionType} />
                                                        </Fragment>
                                                    }
                                                    <div className="mt-3 pb-4">
                                                        {
                                                            selectedTab === 1 ?
                                                                <CustomAmountInput
                                                                    value={amount}
                                                                    key={"Invest"}
                                                                    label="Investment Amount"
                                                                    placeholder="80,000"
                                                                    handleChange={_handleAmount} />
                                                                :

                                                                redemptionType === 1 ?
                                                                    <div className="position-relative mt-3">
                                                                        <CustomTextInput label="Units to redeem"
                                                                            postfix="*"
                                                                            value={unit}
                                                                            type="number"
                                                                            placeholder="1.2"
                                                                            handleChange={_handleUnitChange} />
                                                                    </div>

                                                                    :
                                                                    <div className="position-relative mt-3">
                                                                        <CustomAmountInput
                                                                            value={redeem}
                                                                            postfix="*"
                                                                            label="Amount to redeem"
                                                                            placeholder="50,000"
                                                                            handleChange={_handleRedeemAmount} />
                                                                    </div>

                                                        }
                                                        <div className="d-flex flex-wrap gap-12px mt-3 mb-4">
                                                            {
                                                                AMOUNT_CHIP.map((item, key) =>
                                                                    <span className="px-3 py-2 border-bright-gray border-1px e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black border-radius-20px cursor-pointer user-select-none"
                                                                        key={key}
                                                                        onClick={() => _handleChipClick(item)}>{item.label}</span>
                                                                )
                                                            }
                                                            <span></span>
                                                        </div>
                                                        {
                                                            selectedTab === 1 ?
                                                                <Fragment>
                                                                    <div className="d-md-none d-flex justify-content-center">
                                                                        <div className="col-sm-6 col-7">
                                                                            <PrimaryButton label={"Invest Now"}
                                                                                className="padding-12px-tb w-100"
                                                                                disabled={portfolioDetail.transaction_enabled === false ? true : apiLoader}
                                                                                showLoader={fundType === 2 ? false : apiLoader}
                                                                                onPress={_handleInvest} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-md-block d-none">
                                                                        <PrimaryButton label={"Invest Now"}
                                                                            className="padding-12px-tb w-100"
                                                                            disabled={portfolioDetail.transaction_enabled === false ? true : apiLoader}
                                                                            showLoader={fundType === 2 ? false : apiLoader}
                                                                            onPress={_handleInvest} />
                                                                    </div>
                                                                </Fragment>
                                                                :
                                                                <Fragment>
                                                                    <div className="d-md-none d-flex justify-content-center">
                                                                        <div className="col-sm-6 col-7">
                                                                            <PrimaryButton label={"Redeem Now"}
                                                                                className="padding-12px-tb w-100"
                                                                                disabled={portfolioDetail.transaction_enabled === false ? true : portfolioDetail.current_amount <= 0 ? true : false}
                                                                                showLoader={false}
                                                                                onPress={_handleRedeem} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-md-block d-none">
                                                                        <PrimaryButton label={"Redeem Now"}
                                                                            className="padding-12px-tb w-100"
                                                                            disabled={portfolioDetail.transaction_enabled === false ? true : portfolioDetail.current_amount <= 0 ? true : false}
                                                                            showLoader={false}
                                                                            onPress={_handleRedeem} />
                                                                    </div>
                                                                </Fragment>
                                                        }

                                                    </div>
                                                </div>
                                            </div>}
                                        </div>

                                    </div>
                                </section>
                            </div>
                        </div>
                    </div >
            }
            {
                investMore === true &&
                <InvestMoreModal closeModal={() => _investMore(false)}
                    isReinvest={true}
                    selectedDate={selectedDate}
                    selectedDateString={selectedDateString}
                    isFirstInstallment={2}
                    summary={reInvestData}
                    showInfo={true}
                    amount={amount}
                    lumpsumAmount={amount}
                    status={summaryLoader}
                    checkStatus={() => _getInvestmentSuggestions(true)}
                    invested={() => _getPortfolioDetails(portfolioDetail.investment_type, location.state.id, location.state.fund_type)} />
            }

            {
                fundInvestMore === true &&
                <FundInvestModal isReinvest={true}
                    selectedDate={selectedDate}
                    selectedDateString={selectedDateString}
                    isFirstInstallment={1}
                    summary={reInvestData}
                    showInfo={true}
                    fundType={fundType}
                    id={location.state.id}
                    amount={amount}
                    lumpsumAmount={amount}
                    paymentType={reInvestData.payment_types[0]}
                    status={summaryLoader}
                    closeModal={_handleCloseInvestMoreModal}
                    checkStatus={() => _getMFSummary(true)}
                    invested={() => _getPortfolioDetails(portfolioDetail.investment_type, location.state.id, location.state.fund_type)} />
            }

            {
                goalRedeemData !== null &&
                <GoalRedemptionModal data={goalRedeemData}
                    type={goalRedeemData.investment_type}
                    amount={redeem}
                    closeModal={() => _showGoalRedeemModal(null)}
                    redeemSuccess={() => _getPortfolioDetails(portfolioDetail.investment_type, location.state.id)} />
            }

            {
                mfRedeemData !== null &&
                <FundRedemptionModal data={mfRedeemData}
                    amount={redeem}
                    unit={unit}
                    fundType={fundType}
                    redemptionType={redemptionType}
                    closeModal={_handleCloseMfRedeemModal}
                    redeemSuccess={() => _getPortfolioDetails(portfolioDetail.investment_type, location.state.id)} />
            }

            {
                otherInvestmentModal === 2 &&
                <SWPOrderModal summary={portfolioDetail.swp_data}
                    fundType={fundType}
                    id={location.state.id}
                    closeModal={() => _handleOtherInvestment(null)} />
            }

            {
                otherInvestmentModal === 1 &&
                <SwitchOrderModal summary={portfolioDetail.switch_data}
                    fundType={fundType}
                    id={location.state.id}
                    closeModal={() => _handleOtherInvestment(null)} />
            }

            {
                otherInvestmentModal === 3 &&
                <CreateSTPOrder summary={portfolioDetail.stp_data}
                    fundType={fundType}
                    id={location.state.id}
                    closeModal={() => _handleOtherInvestment(null)} />
            }

        </Fragment >
    )
}

export default PortfolioDetail;